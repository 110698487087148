import axios from 'axios'

$('form#cta').on('submit', function(e) {
  e.preventDefault()

  const formId = $(this).attr('id')

  const spinner = location.protocol + '//' + window.location.hostname + '/images/spinner.svg'
  let button = $('#' + formId + ' button.btn')
  button.html('Submit <img class="spinner" src="' + spinner +  '">')

  const firstName = $('#' + formId + ' input[name="firstName"]').val()
  const lastName = $('#' + formId + ' input[name="lastName"]').val()
  const email = $('#' + formId + ' input[name="email"]').val()
  const code = $('#' + formId + ' select[name="code"]').val()
  const tel = $('#' + formId + ' input[name="tel"]').val()
  const business = $('#' + formId + ' input[name="business"]').val()
  const country = $('#' + formId + ' select[name="country"]').val()
  const region = $('#' + formId + ' input[name="region"]').val()
  const info = $('#' + formId + ' select[name="info"]').val()
  const sample = $('#' + formId + ' input[name="sample"]:checked').val()
  const message = $('#' + formId + ' textarea[name="message"]').val()
  const marketing = $('#' + formId + ' input[name="marketing"]:checked').val()

  const data = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    code: code,
    tel: tel,
    country: country,
    business: business,
    info: info,
    sample: sample,
    message: message,
    marketing: marketing,
    region: region,
    'g-recaptcha-response': getReCaptchaV3Response('contact_us_id')
  }

  axios.post(`/api/contact`, data)
  .then(response => { 
    button.html('Submit')

    $('.files').removeClass('files-locked')
    $('.hotspot-modal a').removeClass('locked')
    refreshReCaptchaV3('contact_us_id','contact_us');

    $('#' + formId + ' .form-control').removeClass('is-invalid')
    $('#' + formId + ' .custom-select').removeClass('is-invalid')
    $('#' + formId + ' .invalid-feedback').remove()

    $('#' + formId + ' input[name="firstName"]').val('')
    $('#' + formId + ' input[name="lastName"]').val('')
    $('#' + formId + ' input[name="email"]').val('')
    $('#' + formId + ' select[name="code"]').val('')
    $('#' + formId + ' select[name="code"]').val('')
    $('#' + formId + ' input[name="tel"]').val('')
    $('#' + formId + ' input[name="business"]').val('')
    $('#' + formId + ' select[name="country"]').val('')
    $('#' + formId + ' input[name="region"]').val('')
    $('#' + formId + ' textarea[name="message"]').val('')
    $('#' + formId + ' select[name="info"]').val('')
    $('#' + formId + ' input[name="sample"]').prop('checked', false)
    $('#' + formId + ' input[name="marketing"]').prop('checked', false)

    $('#form-cta').modal('hide')

  })
  .catch(error => {
    $('#' + formId + ' .form-control').removeClass('is-invalid')
    $('#' + formId + ' .custom-select').removeClass('is-invalid')
    $('#' + formId + ' .invalid-feedback').remove()

    refreshReCaptchaV3('contact_us_id','contact_us');

    const errors = error.response.data.errors

    const recapture = errors['g-recaptcha-response']

    if(typeof recapture !== "undefined"){
      $('#contact_us_id').after('<span class="invalid-feedback d-block" role="alert"><strong>' + recapture[0] + '</strong></span>')
    }

    $.each(errors, function( index, value ){
      let input = $('#' + formId + ' input[name="' + index + '"]')
      input.addClass('is-invalid')

      let select = $('#' + formId + ' select[name="' + index + '"]')
      select.addClass('is-invalid')

      let message = '<span class="invalid-feedback" role="alert"><strong>' + value[0] + '</strong></span>'
      input.after(message)
      select.after(message)
      button.html('Submit')
    })
  })
})

$('#form-cta').on('shown.bs.modal', function () {
  refreshReCaptchaV3('contact_us_id','contact_us')

  const firstName = localStorage.getItem('firstName')
  const lastName = localStorage.getItem('lastName')
  const email = localStorage.getItem('email')
  const country = localStorage.getItem('country')
  const region = localStorage.getItem('region')
  const code = localStorage.getItem('code')

  const formId = 'cta';

  $('#' + formId + ' input[name="firstName"]').val(firstName)
  $('#' + formId + ' input[name="lastName"]').val(lastName)
  $('#' + formId + ' input[name="email"]').val(email)
  $('#' + formId + ' select[name="country"]').val(country)
  $('#' + formId + ' select[name="code"]').val(code)
  $('#' + formId + ' input[name="region"]').val(region)

})

$('form#cta-mobile').on('submit', function(e) {
  e.preventDefault()

  const formId = $(this).attr('id')

  const spinner = location.protocol + '//' + window.location.hostname + '/images/spinner.svg'
  let button = $('#' + formId + ' button.btn')
  button.html('Submit <img class="spinner" src="' + spinner +  '">')

  const firstName = $('#' + formId + ' input[name="firstName"]').val()
  const lastName = $('#' + formId + ' input[name="lastName"]').val()
  const email = $('#' + formId + ' input[name="email"]').val()
  const code = $('#' + formId + ' select[name="code"]').val()
  const tel = $('#' + formId + ' input[name="tel"]').val()
  const business = $('#' + formId + ' input[name="business"]').val()
  const country = $('#' + formId + ' select[name="country"]').val()
  const region = $('#' + formId + ' input[name="region"]').val()
  const info = $('#' + formId + ' select[name="info"]').val()
  const sample = $('#' + formId + ' input[name="sample"]:checked').val()
  const message = $('#' + formId + ' textarea[name="message"]').val()
  const marketing = $('#' + formId + ' input[name="marketing"]:checked').val()

  const data = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    code: code,
    tel: tel,
    country: country,
    region: region,
    business: business,
    info: info,
    sample: sample,
    message: message,
    marketing: marketing,
    'g-recaptcha-response': getReCaptchaV3Response('contact_us_id')
  }

  axios.post(`/api/contact`, data)
  .then(response => { 
    button.html('Submit')

    $('.files').removeClass('files-locked')
    $('.hotspot-modal a').removeClass('locked')

    refreshReCaptchaV3('contact_us_id','contact_us');

    $('#' + formId + ' .form-control').removeClass('is-invalid')
    $('#' + formId + ' .custom-select').removeClass('is-invalid')
    $('#' + formId + ' .invalid-feedback').remove()

    $('#' + formId + ' input[name="firstName"]').val('')
    $('#' + formId + ' input[name="lastName"]').val('')
    $('#' + formId + ' input[name="email"]').val('')
    $('#' + formId + ' select[name="code"]').val('')
    $('#' + formId + ' select[name="code"]').val('')
    $('#' + formId + ' input[name="tel"]').val('')
    $('#' + formId + ' input[name="business"]').val('')
    $('#' + formId + ' select[name="country"]').val('')
    $('#' + formId + ' input[name="region"]').val('')
    $('#' + formId + ' textarea[name="message"]').val('')
    $('#' + formId + ' select[name="info"]').val('')
    $('#' + formId + ' input[name="sample"]').prop('checked', false)
    $('#' + formId + ' input[name="marketing"]').prop('checked', false)

    $('#form-cta-mobile').modal('hide')

  })
  .catch(error => {
    $('#' + formId + ' .form-control').removeClass('is-invalid')
    $('#' + formId + ' .custom-select').removeClass('is-invalid')
    $('#' + formId + ' .invalid-feedback').remove()

    refreshReCaptchaV3('contact_us_id','contact_us');

    const errors = error.response.data.errors

    const recapture = errors['g-recaptcha-response']

    if(typeof recapture !== "undefined"){
      $('#contact_us_id').after('<span class="invalid-feedback d-block" role="alert"><strong>' + recapture[0] + '</strong></span>')
    }

    $.each(errors, function( index, value ){
      let input = $('#' + formId + ' input[name="' + index + '"]')
      input.addClass('is-invalid')

      let select = $('#' + formId + ' select[name="' + index + '"]')
      select.addClass('is-invalid')

      let message = '<span class="invalid-feedback" role="alert"><strong>' + value[0] + '</strong></span>'
      input.after(message)
      select.after(message)
      button.html('Submit')
    })
  })
})

$('#form-cta-mobile').on('shown.bs.modal', function () {
  refreshReCaptchaV3('contact_us_id','contact_us')

  const firstName = localStorage.getItem('firstName')
  const lastName = localStorage.getItem('lastName')
  const email = localStorage.getItem('email')
  const country = localStorage.getItem('country')
  const region = localStorage.getItem('region')
  const code = localStorage.getItem('code')

  const formId = 'cta-mobile';

  $('#' + formId + ' input[name="firstName"]').val(firstName)
  $('#' + formId + ' input[name="lastName"]').val(lastName)
  $('#' + formId + ' input[name="email"]').val(email)
  $('#' + formId + ' select[name="country"]').val(country)
  $('#' + formId + ' select[name="code"]').val(code)
  $('#' + formId + ' input[name="region"]').val(region)

})