import 'magnific-popup/dist/jquery.magnific-popup.min'
import Glide from '@glidejs/glide'
import axios from 'axios'

if ($('#media').length > 0) {
  $('#media select').on('change', function(){
    const selectId = $(this).val()
    const mediaId = $(this).data('media')
    const checked = $(this).parents().eq(1).find('input')
    checked.val(selectId + '|' + mediaId)
  })
}

if ($('.video-popup').length > 0) {
  $('.video-popup').on('click', function(e){
    e.preventDefault()
    const link =  $(this).attr('href')
    const title = $(this).attr('title')
    dataLayer.push({'event':'video_popup','conversionValue': title })
    $.magnificPopup.open({
      items: {
          src: link
      },
      type: 'iframe',
      mainClass: 'mfp-fade',
    }, 0)
  });
}

if ($('.image-popup').length > 0) {
  $('.image-popup').magnificPopup({
    type: 'image',
    mainClass: 'mfp-fade',
    gallery: { 
      enabled: true 
    },
    image: {
      titleSrc: 'title' 
    }
  })
}

$(window).on('load', function(){
  if( $('#video-slider').length > 0) {
    new Glide('#video-slider', {
      type: 'carousel',
      perView: 3,
      autoplay: 3000,
      hoverpause: true,
      Controls: $(this).data('controls'),
      breakpoints: {
        800: {
          perView: 2
        },
        480: {
          perView: 1
        }
      }
    }).mount()
  }
});

function listItemAppend(response) {
  $('.downloads').empty()
  let listItem = ''
  $.each(response.data.media, function( index, value ) {
    const file = value.file
    if(value.file) {
      let image = ''
      if (/video/i.test(value.file.mime_type)) {
        image = location.protocol + '//' + window.location.hostname + '/images/video.svg'
      } else if(/pdf/i.test(value.file.mime_type)) {
        image = location.protocol + '//' + window.location.hostname + '/images/pdf.svg'
      } else if(/vnd/i.test(value.file.mime_type)) {
        image = location.protocol + '//' + window.location.hostname + '/images/power-point.svg'
      }

      listItem += '<li class="border-bottom"><a target="_blank" href="' + file.url + '"><span class="file"><img src="' + image + '" class="mr-3">' + file.name + '</span><span class="download">Download <img class="ml-3" src="' + location.protocol + '//' + window.location.hostname + '/images/hexagon.svg"></span></a></li>'
    }
  })

  if(!listItem) {
    listItem = '<li class="empty">There are no documents matching your selection.</li>'
  }

  $('.downloads').append(listItem)
}

if( $('.nav-section').length > 0) {
  $('.nav-section a').on('click', function(e){
    $('.nav-section li').removeClass('active')
    $(this).parent().addClass('active')
    e.preventDefault()
    const sId = $(this).data('section')
    const rId = this.href.substring(this.href.lastIndexOf('/') + 1)
    axios.get(`/api/media/resource/${rId}/section/${sId}`)
      .then(function (response) {
        listItemAppend(response)
      })
  })
}

if( $('.nav-file-type').length > 0) {
  $('.nav-file-type a').on('click', function(e){
    $('.nav-file-type li').removeClass('active')
    $(this).parent().addClass('active')
    e.preventDefault()
      const type = $(this).data('type')
      const rId = this.href.substring(this.href.lastIndexOf('/') + 1)
      const sId = $('.nav-section .active a').data('section')
      axios.get(`/api/media/resource/${rId}/section/${sId}/type/${type}`)
      .then(function (response) {
        listItemAppend(response)
      })
  })
}