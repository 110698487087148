import Dropzone from 'dropzone'

if ($('.dropzone').length > 0) {

  Dropzone.autoDiscover = false
  const myDropzone = new Dropzone('.dropzone', { 
    url: '/api/upload',
    withCredentials: true,
    uploadMultiple: true,
    autoProcessQueue: false,
    addRemoveLinks: true,
    maxFilesize: 500,
    timeout: 300000,
    maxFiles: 50,
    acceptedFiles: 'application/pdf, video/*, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint, image/*',
    parallelUploads: 12,
    dictDefaultMessage: 'Drop files here to upload.',
    init: function() {
      this.on('success', function(file) {
        setTimeout(function(){
          myDropzone.removeFile(file)
        }, 3000)
      })

      this.on('error', function() {
        console.log('error')
    })
  }
  })

  $('#upload').on('click', function(e){
    e.preventDefault()
      myDropzone.processQueue()
  })
}