import axios from 'axios'

$('form#password').on('submit', function(e) {
  e.preventDefault();
  const formId = $(this).attr('id')
  const roomId = $(this).data('room');
  const password = $('#' + formId + ' input[name="password"]').val()

  const data = {
    password: password,
  }

  axios.post(`/api/password/${roomId}`, data)
  .then(response => { 
    $('#form-modal').modal('hide');
  }).catch(error => {

    $('#' + formId + ' .form-control').removeClass('is-invalid')
    $('#' + formId + ' .invalid-feedback').remove()

    const errors = error.response.data.error
    let input = $('#' + formId + ' input[name="password"]')
    input.addClass('is-invalid')
    let message = '<span class="invalid-feedback" role="alert"><strong>' + errors + '</strong></span>'
    input.after(message)
  })
});
