import { gsap } from 'gsap'

function videoFadeOut(e) {
  if(this.currentTime > this.duration -1) {
    if(e.currentTarget.callOnce) {
      e.currentTarget.callOnce = false
      const video = document.querySelector("#intro-video")
      gsap.to(video, {opacity: 0, display: 'none', duration: 1})
      let now = new Date()
      let expiry = now.setTime(now.getTime() + 24 * 60 * 60 * 1000)
      now.setTime(expiry)
      document.cookie = 'intro=true;expires=' + now.toGMTString() + ';path=/'
    }
  }
}

if($('#intro-video').length > 0) {
  $('#app').removeClass('d-none')
  const video = document.querySelector("#intro-video video")
  video.addEventListener("timeupdate", videoFadeOut,false)
  video.callOnce = true
}
