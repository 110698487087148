import Swal from 'sweetalert2'
import axios from 'axios'

if( $('#room-select').length > 0) {
  $('#room-select').on('change', function(){
    const rId = $(this).val()
    if(rId){
      axios.get(`/api/room/image/${rId}`)
      .then(function (response) {
        $('#hotspot-image').attr('src', response.data.image)
        if($('#add-hotspot').length > 0) {
          $('#add-hotspot').removeClass('d-none')
        }
      })
    }
  })
}

$('form.delete button').on('click', function(e){
  e.preventDefault()

  Swal.fire({
    title: 'Delete',
    text: "Are you sure you want to delete this item.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#dc3545',
    cancelButtonColor: '#4f3450',
    confirmButtonText: 'Delete'
  }).then((result) => {
    if (result.isConfirmed) {
      const form = $(this).closest('form')
      $(form).trigger("submit")  
    }
  })
})

$("#search-media").on('keyup', function(){
  const filter = $(this).val().toLowerCase();

  $('td.name').each(function(){
    if ($(this).text().trim().toLowerCase().indexOf(filter) > -1) {
      $(this).closest('tr').show();
    }else{
      $(this).closest('tr').hide();
    }
  })
})