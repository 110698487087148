import { gsap } from 'gsap'
import 'magnific-popup/dist/jquery.magnific-popup.min'

$(document).on('click','.hotspot-modal a.locked',function(e){
  e.preventDefault()
  const text = $(this).data('text')
  $('#form-text').html(text)
  const link =  $(this).attr('href')
  const hotspotId = $(this).parent().data('slug')
  const oldClass = $(this).data('old')

  $('input[name="hotspot-class"]').val(oldClass)
  $('input[name="hotspot-id"]').val(hotspotId)
  $('input[name="hotspot-link"]').val(link)
  $('#form-modal').modal()
});

// Animate hotspots on open
$('.hotspot a.open').on('click', function(e) {
  e.preventDefault()
  const active = $(this).attr('data-active')

  gsap.to('.active', {opacity: 0, display: 'none', scale: 0}) 
  $(".hotspot-content.active").removeClass("active")
  
    gsap.to(active, {opacity: '1', display:'block', scale: 1, duration: 1})
    $(active).addClass('active')
})

// Close open hotspots on click
$('.hotspot-content').on('click', function() {
  gsap.to($(this), {opacity: 0, display: 'none', scale: 0})
  $(this).removeClass("active")
})

// Animate hotspots on hover
function animate(i, element) {
  const tl = gsap.timeline({ repeat: -1 })
  tl.to(element, { opacity: 0, scale: 2, duration: 1 })
  tl.pause()

  $(element).on('click', function(){
    tl.progress(0).pause()
    const hover = $(this).parents().eq(1).find('.hotspot-hover')
    gsap.to(hover, {opacity: '0', display:'none', duration: 0.5})
  })

  $(element).on('mouseenter', function(){
    tl.play(0)
    $('.hotspot-hover').hide();
    const hover = $(this).parents().eq(1).find('.hotspot-hover')
    gsap.to(hover, {opacity: '1', display:'block', duration: 1})

  }).on('mouseleave', function(){
    tl.progress(0).pause()
    const hover = $(this).parents().eq(1).find('.hotspot-hover')
    gsap.to(hover, {opacity: '0', display:'none', duration: 0.5})
  })
}

$(".desktop-view .hotspot .animate").each(animate)

// Alays pulse hotspots with a class of pulse
function pulse(i, element) {
  const tl = gsap.timeline({ repeat: -1 })
  tl.to(element, { opacity: 0, scale: 1.5, duration: 2 })
  tl.play()
}

$(".hotspot .pulse").each(pulse)


function getPosition(el) {
  let xPos = 0
  let yPos = 0

  while (el) {
    if (el.tagName == "BODY") {
      var xScroll = el.scrollLeft || document.documentElement.scrollLeft
      var yScroll = el.scrollTop || document.documentElement.scrollTop

      xPos += (el.offsetLeft - xScroll + el.clientLeft)
      yPos += (el.offsetTop - yScroll + el.clientTop)
    } else {
      xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft)
      yPos += (el.offsetTop - el.scrollTop + el.clientTop)
    }
    el = el.offsetParent
  }
  return {
    x: xPos,
    y: yPos
  }
}

// Set position of hotspots
if( $('#hotspot-image').length > 0) {
  $('#hotspot-image').on('click', function(e){

    const hotspot = $('.hotspot')

    let parentPosition = getPosition(e.currentTarget)
    let xPosition = e.clientX - parentPosition.x - (hotspot.width() / 2)
    let yPosition = e.clientY - parentPosition.y - (hotspot.width() / 2)
    hotspot.css('left', xPosition + 'px')
    hotspot.css('top', yPosition + 'px')

    const containerWidth = $('.hotspot-select').width()
    const containerHeight = $('.hotspot-select').height()

    let percentageLeft = xPosition / containerWidth * 100;
    let percentageTop = yPosition / containerHeight * 100;

    $('input[name=x]').val(percentageLeft + '%');
    $('input[name=y]').val(percentageTop + '%');

  })
}

// Hotspot link open new page or model
if( $('.next-page').length > 0) {
  $(document).on('click','.next-page',function(e){
    e.preventDefault()
    const link =  $(this).attr('href')

    if(link.indexOf(".pdf") != -1){
      window.open(link)
    }

    if(link.indexOf(".pdf") == -1){
      if (/media/i.test(link)){
        const title = $(this).data('title')
        dataLayer.push({'event':'video_popup','conversionValue': title })

        $.magnificPopup.open({
          items: {
              src: link
          },
          type: 'iframe',
          mainClass: 'mfp-fade',
        }, 0)

      }else {
        window.location = link
      }
    }
  })
}

// Calcuate width of hotspot images
if($(".dekstop-view .hotspot-wrap").length > 0) {

  const outerContent = $('.dekstop-view .room');
  const innerContent = $('.dekstop-view .room img');

  outerContent.scrollLeft( (innerContent.width() - outerContent.width()) / 2);
  
  $(window).on('resize load', function(){
    const room = $('.dekstop-view .room img')
    const roomWidth = room.width()
    const roomHeight = room.height()

    $(".dekstop-view .hotspot-wrap").css('width', roomWidth + 'px')
    $(" .dekstop-view .hotspot-wrap").css('height', roomHeight + 'px')
  })
}