import axios from 'axios'

// Submit data capture form
$('form.data-capture').on('submit', function(e) {
  e.preventDefault()

  const formId = $(this).attr('id')

  const spinner = location.protocol + '//' + window.location.hostname + '/images/spinner.svg'
  let button = $('#' + formId + ' button.btn')
  button.html('Submit <img class="spinner" src="' + spinner +  '">')

  const firstName = $('#' + formId + ' input[name="firstName"]').val()
  const lastName = $('#' + formId + ' input[name="lastName"]').val()
  const email = $('#' + formId + ' input[name="email"]').val()
  const country = $('#' + formId + ' select[name="country"]').val()
  const region = $('#' + formId + ' input[name="region"]').val()
  const code = $('#' + formId + ' select[name="country"]').find(":selected").data('code')
  const marketing = $('#' + formId + ' input[name="marketing"]:checked').val()
  const hotspotLink = $('#' + formId + ' input[name="hotspot-link"]').val()
  const mediaHotspotId = $('#' + formId + ' input[name="hotspot-id"]').val()
  const hotspotClass = $('#' + formId + ' input[name="hotspot-class"]').val()

  const hotspotId = $(this).data('hotspot')

  const data = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    country: country,
    region: region,
    marketing: marketing,
    'g-recaptcha-response': getReCaptchaV3Response('data_capture_id')
  }

  axios.post(`/api/form/${hotspotId}`, data)
  .then(response => { 
    const slug = response.data.slug
    button.html('Submit')

    $('.files').removeClass('files-locked')

    if(hotspotLink){

      $('div[data-slug=' + mediaHotspotId + '] a').removeClass('locked');
      $('div[data-slug=' + mediaHotspotId + '] a').addClass(hotspotClass);

      if (/media/i.test(hotspotLink)){
        const title = $(this).data('title')
        dataLayer.push({'event':'video_popup','conversionValue': title })
  
        $.magnificPopup.open({
          items: {
              src: hotspotLink
          },
          type: 'iframe',
          mainClass: 'mfp-fade',
        }, 0)
  
      }else {
        window.location = hotspotLink
      }
    }

    localStorage.setItem('firstName', firstName)
    localStorage.setItem('lastName', lastName)
    localStorage.setItem('email', email)
    localStorage.setItem('country', country)
    localStorage.setItem('region', region)
    localStorage.setItem('code', code)

    refreshReCaptchaV3('contact_us_id','contact_us');
    $('#form-modal').modal('hide');
  })
  .catch(error => {

    $('#' + formId + ' .form-control').removeClass('is-invalid')
    $('#' + formId + ' .custom-select').removeClass('is-invalid')
    $('#' + formId + ' .invalid-feedback').remove()

    refreshReCaptchaV3('data_capture_id','data_capture')

    const errors = error.response.data.errors

    const recapture = errors['g-recaptcha-response']

    if(typeof recapture !== "undefined"){
      $('#data_capture_id').after('<span class="invalid-feedback d-block" role="alert"><strong>' + recapture[0] + '</strong></span>')
    }

    $.each(errors, function( index, value ){
      let input = $('#' + formId + ' input[name="' + index + '"]')
      input.addClass('is-invalid')

      let select = $('#' + formId + ' select[name="' + index + '"]')
      select.addClass('is-invalid')

      let message = '<span class="invalid-feedback" role="alert"><strong>' + value[0] + '</strong></span>'
      input.after(message)
      select.after(message)
      button.html('Submit')
    })
  })
})

$('#form-modal').on('hidden.bs.modal', function () {
  $('input[name="hotspot-link"]').val(null)
  $('input[name="hotspot-id"]').val(null)
  $('input[name="hotspot-class"]').val(null)
  window.history.pushState({}, document.title, window.location.href.split('?')[0])
})

$(document).on('click','.files-locked a',function(e){
  e.preventDefault()
    $('#form-modal').modal()
});


$(window).on('load', function() {
  if($('#form-modal').length > 0){
    $('#form-modal').modal({
      backdrop: 'static',
      keyboard: false
    });
  }
});