$('select[name="country"]').on('change', function(){
  const code = $(this).find(":selected").data('code')
  $('select[name="code"]').val(code)
});

var optionValues1 =[];
$('#form-cta-mobile select[name="code"] option').each(function(){
  if($.inArray(this.value, optionValues1) >-1){
      $(this).remove()
  } else {
    optionValues1.push(this.value);
  }
});

var optionValues2 =[];
$('#form-cta select[name="code"] option').each(function(){
  if($.inArray(this.value, optionValues2) >-1){
      $(this).remove()
  } else {
    optionValues2.push(this.value);
  }
});